import React from 'react'
import { RealContact } from '../components/contact/Contact'
const testy = () => {
    return (
        <div style={{marginTop:'20%', marginBottom:'20%'}}>
            <RealContact />
        </div>
    )
}

export default testy
